import { useMutation } from '@apollo/client/index.js';
import useQuery from '@engined/client/hooks/useQuery.js';
import PageHeader from '@asaprint/asap/components/PageHeader.js';
import PagePaper from '@asaprint/asap/components/PagePaper.js';
import {
  GraphQLReactTable as TGraphQLReactTable,
  GraphQLReactTableProps,
} from '@asaprint/asap/components/ReactTable.js';
import CenteredCell from '@asaprint/asap/components/tables/CenteredCell.js';
import authorized from '@asaprint/asap/decorators/authorized.js';
import { RouteHandle } from '@asaprint/asap/interfaces.js';
import { COLUMN_ACTIONS, TOOLTIP_EDIT } from '@asaprint/asap/locales/client.js';
import {
  ADMIN_PHASE_GROUPS_CREATE_ROUTE,
  ADMIN_PHASE_GROUPS_EDIT_ROUTE,
  DASHBOARD_ROUTE,
} from '@asaprint/asap/routes.js';
import {
  PhaseGroups_ChangePositionSave,
  PhaseGroups_Load,
  PhaseGroups_MaxPositionLoad,
} from '@asaprint/asap/routes/__authenticated/admin/phase-groups/index.graphql';
import {
  PhaseGroups_ChangePositionSaveMutation,
  PhaseGroups_ChangePositionSaveMutationVariables,
  PhaseGroups_LoadQuery,
  PhaseGroups_MaxPositionLoadQuery,
  PhaseGroups_MaxPositionLoadQueryVariables,
  PhaseGroups_PhaseGroupFragment,
  TimeEntriesProductCreative_LoadProductQuery,
} from '@asaprint/asap/schema.client.types.js';
import { Permission } from '@asaprint/common/access.js';
import Loading from '@engined/client/components/Loading.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { displayError } from '@engined/client/helpers/errors.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { getLogger } from '@engined/core/services/logger.js';
import { url } from '@engined/core/services/routes.js';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add, ArrowCircleDown, ArrowCircleUp, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Column, TableState } from 'react-table';

const logger = getLogger('routes/__authenticated/admin/phases-groups/index');

const GraphQLReactTable = TGraphQLReactTable as unknown as React.NamedExoticComponent<
  GraphQLReactTableProps<PhaseGroups_PhaseGroupFragment>
>;

interface OwnProps {}

type Props = OwnProps;

const emptyArray = [];
const defaultSortBy: TableState['sortBy'] = [{ id: 'position', desc: false }];

const mapData = (data: PhaseGroups_LoadQuery) => data?.phaseGroups?.edges.map((e) => e.node) || emptyArray;
const mapCount = (data: PhaseGroups_LoadQuery) => data?.phaseGroups?.pageInfo.count || 0;

const Page: React.FunctionComponent<Props> = () => {
  const { t } = useLocale();
  const { data, loading, error } = useQuery<
    PhaseGroups_MaxPositionLoadQuery,
    PhaseGroups_MaxPositionLoadQueryVariables
  >(PhaseGroups_MaxPositionLoad);

  const maxPosition = data?.phaseGroups?.pageInfo.count;

  const columns: Column<PhaseGroups_PhaseGroupFragment>[] = useMemo(
    () => [
      {
        Header: t(COLUMN_ACTIONS),
        accessor: 'id',
        disableFilters: true,
        disableSorting: true,
        width: 50,
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value }, row: { original } }) => (
          <CenteredCell>
            <Tooltip title={t(TOOLTIP_EDIT)}>
              <IconButton component={RouterLink} to={url(ADMIN_PHASE_GROUPS_EDIT_ROUTE, { id: value })} size="small">
                <Edit />
              </IconButton>
            </Tooltip>
            <ChangePositionButton direction="up" phaseGroup={original} disabled={original.position === 1} />
            <ChangePositionButton direction="down" phaseGroup={original} disabled={original.position === maxPosition} />
          </CenteredCell>
        ),
      },
      {
        Header: 'Pozícia',
        accessor: 'position',
        width: 50,
      },
      {
        Header: 'Názov',
        accessor: 'name',
        width: 200,
      },
    ],
    [t, maxPosition],
  );

  return (
    <>
      <PageHeader
        title={
          <>
            <FontAwesomeIcon icon={faLayerGroup} /> Fázy produktu - skupiny
          </>
        }
        actions={
          <Box display="flex" justifyContent="flex-end">
            <Button
              component={RouterLink}
              to={ADMIN_PHASE_GROUPS_CREATE_ROUTE}
              color="primary"
              variant="contained"
              startIcon={<Add />}
            >
              Nová skupina
            </Button>
          </Box>
        }
      />

      <PagePaper>
        {error || (loading && !data) ? (
          <Loading error={error} />
        ) : (
          <GraphQLReactTable
            id="admin-phase-groups"
            columns={columns}
            defaultSortBy={defaultSortBy}
            query={PhaseGroups_Load}
            mapCount={mapCount}
            mapData={mapData}
            canPrint={false}
            canXLSXExport={false}
          />
        )}
      </PagePaper>
    </>
  );
};

export default authorized(Permission.PhaseGroupsManage)(Page);

interface PhaseGroup {
  id: string;
  updatedAt?: string;
  position: number;
}

interface ChangePositionButtonOwnProps {
  phaseGroup: PhaseGroup;
  direction: 'up' | 'down';
  disabled: boolean;
}

const ChangePositionButton: React.FunctionComponent<ChangePositionButtonOwnProps> = ({
  phaseGroup,
  direction,
  disabled,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [execute, { loading }] = useMutation<
    PhaseGroups_ChangePositionSaveMutation,
    PhaseGroups_ChangePositionSaveMutationVariables
  >(PhaseGroups_ChangePositionSave, {
    variables: {
      id: phaseGroup.id,
      updatedAt: phaseGroup.updatedAt,
      position: direction === 'up' ? phaseGroup.position - 1 : phaseGroup.position + 1,
    },
    refetchQueries: ['PhaseGroups_Load'],
  });

  const onClick = useEventCallback(async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      await execute();
    } catch (err) {
      displayError(err, enqueueSnackbar, logger);
    }
  });

  return (
    <Tooltip title={direction === 'up' ? 'Posunúť vyššie' : 'Posunúť nižšie'}>
      <span>
        <IconButton disabled={loading || disabled} onClick={onClick} size="small">
          {direction === 'up' ? <ArrowCircleUp /> : <ArrowCircleDown />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

ChangePositionButton.displayName = 'ChangePositionButton';

export const loader = async ({ params, request, context: { req, apollo } }: LoaderFunctionArgs) => {
  const result = await apollo.query<PhaseGroups_MaxPositionLoadQuery, PhaseGroups_MaxPositionLoadQueryVariables>({
    query: PhaseGroups_MaxPositionLoad,
  });
  return result.data;
};

export const handle: RouteHandle = {
  breadcrumbs: [
    { text: 'Dashboard', to: DASHBOARD_ROUTE },
    {
      text: 'Administrácia',
    },
    {
      text: 'Fázy produktu - skupiny',
    },
  ],
  meta: ({ meta }: MetaFunctionArgs<TimeEntriesProductCreative_LoadProductQuery>) => {
    return {
      title: `Fázy produktu - skupiny | Administrácia | ${meta.title}`,
    };
  },
};
